import { createApp } from 'vue'
import App from './App.vue'
import cookie from './plugins/cookie'
//import i18n from './i18n'
import router from './router'
import store from './store'
import VueLogger from 'vuejs3-logger';

const isProduction = process.env.VUE_APP_NODE_ENV === 'production';
const options = {
    isEnabled: true,
    logLevel : isProduction ? 'error' : 'debug',
    stringifyArguments : false,
    showLogLevel : true,
    showMethodName : true,
    separator: '|',
    showConsoleColors: true,
};



createApp(App)
    .use(store)
    .use(router)
//    .use(i18n)
    .use(cookie)
    .use(VueLogger,options)
    .mount('#app')
