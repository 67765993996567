import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

import firebaseConfig from "./firebaseConfig"


// Initialisez Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Obtenez une référence au stockage Firebase
const storage = getStorage(firebaseApp);

export { storage, firebaseApp }; // Exportez pour pouvoir utiliser dans d'autres fichiers
