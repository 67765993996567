<template>
  <router-view/>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css" integrity="sha512-MV7K8+y+gLIBoVD59lQIYicR65iaqukzvf/nwasF0nqhPay5w/9lJmVM2hMDcnK1OnMGCdVK+iQrJ7lzPJQd1w==" crossorigin="anonymous" referrerpolicy="no-referrer" />

</template>

<style lang="scss">
html,body{
  margin:0;
  padding:0;
  width: 100%;
  height: 100%;
}

.fas{
  font-family: FontAwesome !important;
}
</style>



<script>

export default {
  name: 'App',
  setup: function () {
  },
};

</script>
