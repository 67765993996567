import {getAuth} from "firebase/auth";
import {
    GoogleAuthProvider,
    FacebookAuthProvider,
    signInWithPopup,
    signInWithEmailAndPassword,
    OAuthProvider,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
} from "firebase/auth";
import store from './../store';
import { initializeApp } from "firebase/app";

//injected by docker
import firebaseConfig from "./firebaseConfig"

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export default {
    authenticate : function (provider){
        return signInWithPopup(auth, provider).
            then((result)=>{
                return result
            })
    },
    handleAuthentication : function (user) {
        return user.getIdToken(true).
            then(token => {
                let result = {
                    accessToken : token,
                    refreshToken : user.refreshToken,
                    user : user,
                    expiration : new Date(user.stsTokenManager.expirationTime)
                }
                return result
            }).then(result => this.setAuthenticationInStore(result))
    },
    setAuthenticationInStore : function (result){
        return store.dispatch('user/authenticate', result)
    },
    loginWithGoogle: function (){
        let provider = new GoogleAuthProvider()
        provider.addScope('profile');
        provider.addScope('email');
        return this.authenticate(provider)
            .then((result)=> this.handleAuthentication(result.user))
    },
    loginWithFacebook: function (){
        let provider = new FacebookAuthProvider()
        provider.addScope('profile');
        provider.addScope('email');
        return this.authenticate(provider)
            .then((result)=> this.handleAuthentication(result.user))

    },
    loginWithApple: function (){
        let provider = new OAuthProvider('apple.com');
        provider.addScope('name');
        provider.addScope('email');
        return this.authenticate(provider)
            .then((result)=> this.handleAuthentication(result.user))

    },
    loginWithPassword: function (email,password){
        return signInWithEmailAndPassword(auth, email, password)
            .catch((error) => {
                const errorCode = error.code;
                if(errorCode === "auth/user-not-found") {
                    return createUserWithEmailAndPassword (auth, email, password)
                        .then(usercredential => this.handleAuthentication(usercredential.user))
                        .catch(reason => {
                            console.debug(reason);//probably week password
                            throw reason
                        })
                }else{
                    throw error;
                }
            })
            .then((userCredential) => {
                return this.handleAuthentication(userCredential.user)
            })

    },
    sendPasswordResetEmail : function (email){
        return sendPasswordResetEmail(email)
    }
}