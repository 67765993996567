//default one for code completion, should be overwrited
const firebaseConfig = {
    apiKey: "AIzaSyBbqwf9GAtTqcM9c_U3zSw6bhV4DCnY8vQ",
    authDomain: "geocaching-base.firebaseapp.com",
    projectId: "geocaching-base",
    storageBucket: "geocaching-base.appspot.com",
    messagingSenderId: "327352887217",
    appId: "1:327352887217:web:2a2aecc66585b449757f90",
    measurementId: "G-M0HLLXQ18E"
};
export default firebaseConfig