import VueCookies from "vue-cookies";

const state = () => ({
    user: null,
    uid: null,
    accessToken : null,
    refreshToken : null,
    expirationDate : null,
    loggedIn:false,
    loading: false,
    loaded: false,
});

const getters = {
    getUser : function(){ return state.user},
    getUid : function(){ return state.uid},
    getAccessToken : function(){ return state.accessToken},
    getRefreshToken : function(){ return state.refreshToken},
    getLoggedIn : function(){ return state.loggedIn},
    getLoading : function(){ return state.loading},
    getLoaded : function(){ return state.loaded},
}

const actions = {
    authenticate: function ({ state, commit }, { accessToken,refreshToken, user, expiration }) {
        return Promise.resolve(commit("set",{name:"loading", value:true}))
            .then(  Promise.resolve(commit("set", {name: "user", value: user})) )
            .then(  Promise.resolve(commit("set", {name: "uid", value: user.uid})) )
            .then(  Promise.resolve(commit("set", {name: "accessToken", value:accessToken})) )
            .then(  Promise.resolve(commit("set", {name: "refreshToken", value:refreshToken})) )
            .then(  Promise.resolve(commit("set", {name: "expirationDate", value:expiration})) )
            .then(  Promise.resolve(commit("set", {name: "loggedIn", value:true})) )
            .then(  Promise.resolve(commit("set", {name: "loading", value:false})) )
    },
}

const mutations = {
    set(state, {name,value} ) {
        state[name] = value;
        VueCookies.set(name,value);
    },
    unset(state,  name ) {
        state[name] = null;
        delete state[name];
        VueCookies.remove(name)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}

