<template>
  <EmailAuthModal v-if="displayMailModal" @close="authenticateWithEmail()"/>
  <main>
    <div id="login" v-if="!this.isAuthenticated">
      <img v-on:click="authenticateWithGoogle"
           src="./../assets/google-logo.png"  style="width:53px"/>
      <img v-on:click="authenticateWithApple"
           src="./../assets/apple-logo.png"   style="width:50px"/>
      <img v-on:click="authenticateWithFacebook"
           src="./../assets/facebook-logo.png" style="width:56px;height:56px"/>
      <div class="mailbutton" v-on:click="authenticateWithEmail" >
        <div class="container">
          <i class="fas fa-envelope"></i>
        </div>
      </div>
    </div>

    <div v-if="this.isAuthenticated">
      <br>
      <div>
        <h1>access</h1>
        <div class="accessTokenContainer">
          <p class="accessToken" v-text="this.accessToken"/>
        </div>
        <h1>refresh</h1>
        <div class="accessTokenContainer">
          <p class="accessToken" v-text="this.refreshToken"/>
        </div>
        <h1>uid</h1>
        <div class="accessTokenContainer">
          <p class="accessToken" v-text="this.uid"/>
        </div>
      </div>
      <div class="bloc">
        <img :src=this.user.user.photoURL id="avatar" /><br>
        Welcome {{this.user.user.displayName}}<br>
        {{this.user.user.email}}
      </div>
      <div class="bloc">
        <select v-model="selectedEnv">
          <option v-for="env in this.env" v-bind:key="env" :value="env" >
            {{env}}
          </option>
        </select>
        <select  v-model="selectPath">
          <option v-for="path in this.path" v-bind:key="path" :value="path" >
            {{path}}
          </option>
        </select>
        <br>
        <input type="file" @change="handleFileUpload">
        <br>
        <button @click="uploadFile">upload</button>
        <p>{{status}}</p>
      </div>

    </div>
  </main>


</template>

<script>
import {mapState} from "vuex";
import EmailAuthModal from '@/components/EmailAuthModal';
import auth from "@/services/auth"
import {  ref } from 'vue';
import { ref as storageRef, uploadBytes } from 'firebase/storage';
import { storage } from "../services/storage"

export default {
  name: 'Home',
  components: {
    EmailAuthModal,
  },
  data(){
    return{
      file:null,
      path:{
        ios: "ios",
        mac: "mac",
        android: "android",
        windows: "windows",
      },
      env:{
        dev:"dev",
        prod:"prod"
      },
      selectPath:"ios",
      selectedEnv:"dev",
      status:"",
    }
  },
  computed:{
    ...mapState({
      isAuthenticated : state => state.user.loggedIn,
      user : state => state.user,
      accessToken :  state => state.user.accessToken,
      refreshToken :  state => state.user.refreshToken,
      uid :  state => state.user.uid,
    }),
  },
  methods: {
    authenticateWithGoogle: function () {
      return auth.loginWithGoogle()
    },
    authenticateWithFacebook: function () {
      return auth.loginWithFacebook()
    },
    authenticateWithApple: function () {
      return auth.loginWithApple()
    },
    async uploadFile() {
      try {
        this.status="uploading..."
        let url = "asb/"+ this.selectedEnv+"/" + this.selectPath +"/"+ this.file.name
        console.log(url)
        const storageReference  = storageRef(storage, url);
        await uploadBytes(storageReference, this.file);
        this.status="File uploaded successfully"
      } catch (error) {
        this.status='Error uploading file:', error
      }
    },
    handleFileUpload(event) {
      this.file =  event.target.files[0]
    }
  },
  mounted() {
    return
  },
  watch:{
    async isAuthenticated() {
      //auto redirect
      window.location = `uniwebview://login?accessToken=${this.accessToken}&refreshToken=${this.refreshToken}&userId=${this.uid}`;
      console.debug(this.user)
    }
  },
  setup: function() {
    const authenticateWithEmail = () => {
      displayMailModal.value=!displayMailModal.value;
    }
    const displayMailModal  = ref(false)
    return {
      displayMailModal,
      authenticateWithEmail
    };
  },
}
</script>
<style lang="scss" scoped>

#login {
  text-align: center;
}
#avatar{
  width: 30px;
}
#login > *{
  margin-right: 1em;
}

.logintext {
  margin-bottom: 2em;
  color: #d2d2dc;
  line-height: 1.1em;
  font-size: 1.2em;
  word-spacing: 0.1em;

}

.mailbutton{
  display: inline-block;
  width: 50px;
  height: 50px;
  background: white;
  vertical-align: middle;
  border-style: none;
  border-radius: 2em;
  cursor: pointer;
  margin-top: -2.7em;
  & .container{
    width: 100%;
    height: 100%;
    display: flex;
  }
  & .container i{
    margin-top:auto;
    margin-bottom:auto;
    font-size: 1.5em;
    margin-left: 0.55em;
  }
}

.accessTokenContainer{
  background-color: grey;
  width: 80%;
  border-radius: 1em;
  margin: auto;
}
.accessToken{
  padding: 1em;
  overflow-x: scroll;
  width: 80%;
  color: white;
  margin: auto;
  font-size: 0.75em;
}
.bloc{
  background-color: #363636;
  width: 60%;
  border-radius: 1em;
  margin: auto;
  margin-top: 1em;
  padding: 0.25em;
  color:white;
  & * {
    margin: 0.25em;
  }
}
h1{
  color:white;
}
*{
  font-size: 0.9em;
}



</style>