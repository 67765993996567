<template>
  <div>
    <svg
      :color="color"
      class="rotate"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="65"
      height="65"
      viewBox="0 0 65 65"
    >
      <defs>
        <clipPath id="a">
          <rect class="a" x="3" y="3" width="65" height="65" />
        </clipPath>
      </defs>
      <g class="b" transform="translate(-3 -3)">
        <g transform="translate(5.28 5.282)">
          <path
            class="c"
            d="M103.6,36.417s-.008,0-.008,0a7.035,7.035,0,0,1-6.726-2.336,8.356,8.356,0,0,1-1.982-7.308,16.4,16.4,0,0,1,.865-2.92,4.47,4.47,0,0,0-2.815,2.685c-.846-2.037-1.149-2.274-2.28-5.006v0c-.022-.139-.091-6.148,1.751-7.231-1.265.113-2.283.937-3.077,4.18-.523-1.157-1.1-2.363-1.745-3.615-3.3-6.421-8.335-9.61-12.663-10.335,8.959.126,14.466,3.487,20.323,8.965A25.026,25.026,0,0,0,81.013,4.389c-15.332-3.283-23.186,6.461-24.8,8.252C60.5,4.532,67.768,1.106,75.58,1.579,81.868-.551,91.26-1.57,99.649,5.5a18.819,18.819,0,0,1,3.19,3.163c-2.219-.475-4.806-.817-6.563.7a14.144,14.144,0,0,1,9.012,2.468c.349.518,1.081,1.989,1.386,2.512a4.869,4.869,0,0,0-4.15-.439c3.03.131,3.643,1.008,5.032,1.949a14.935,14.935,0,0,1,1.581,4.427c-1.509-1.271-5.662-3.305-7.98-2.862,4.016-.072,8.585,7.212,8.664,7.369v0C110.1,30.769,107.653,35.491,103.6,36.417Z"
            transform="translate(-49.214 0)"
          />
          <path
            class="c"
            d="M6.242,193.577s.008,0,.008,0a7.034,7.034,0,0,1,6.726,2.336,8.356,8.356,0,0,1,1.982,7.308,16.4,16.4,0,0,1-.865,2.92,4.47,4.47,0,0,0,2.815-2.685c.846,2.037,1.149,2.274,2.28,5.006v0c.022.139.091,6.148-1.751,7.231,1.265-.113,2.283-.937,3.077-4.18.523,1.157,1.1,2.363,1.745,3.615,3.3,6.421,8.335,9.611,12.663,10.335-8.959-.126-14.466-3.487-20.323-8.965A25.026,25.026,0,0,0,28.83,225.6c15.332,3.283,23.186-6.461,24.8-8.252-4.278,8.109-11.55,11.536-19.362,11.062-6.289,2.13-15.68,3.149-24.069-3.921A18.818,18.818,0,0,1,7,221.33c2.218.475,4.806.817,6.563-.7a14.144,14.144,0,0,1-9.012-2.468c-.349-.518-1.081-1.989-1.386-2.512a4.869,4.869,0,0,0,4.15.439c-3.03-.131-3.643-1.008-5.032-1.949a14.935,14.935,0,0,1-1.581-4.427c1.509,1.271,5.662,3.3,7.98,2.862C4.67,212.651.1,205.367.022,205.21v0C-.252,199.225,2.19,194.5,6.242,193.577Z"
            transform="translate(0 -169.321)"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "LoadingSpinner",
  props: {
    color: {
      type: String,
      default: "#F3EFEB",
    },
  },
};
</script>

<style lang="scss" scoped>
.a {
  fill: none;
}
.b {
  isolation: isolate;
  clip-path: url(#a);
}
.c {
  fill: currentColor;
}

.rotate {
  max-width: 100%;
  max-height: 100%;
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
